export const toggleElementVisibility = (element, action) => {
  if (action == 'ON') {
    if (element.hasAttribute('hidden')) {
      element.removeAttribute('hidden');
    }
  }

  if (action == 'OFF') {
    element.setAttribute('hidden', '');
  }
};

export const createControlButton = (textContent, title, color) => {
  const controlButton = document.createElement('button');
  controlButton.style.backgroundColor = '#fff';
  controlButton.style.border = '2px solid #fff';
  controlButton.style.borderRadius = '3px';
  controlButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
  controlButton.style.color = color;
  controlButton.style.cursor = 'pointer';
  controlButton.style.fontSize = '12px';
  controlButton.style.lineHeight = '20px';
  controlButton.style.margin = '6px 0 22px 3px';
  controlButton.style.padding = '0 5px';
  controlButton.style.textAlign = 'center';
  controlButton.textContent = textContent;
  controlButton.title = title;
  controlButton.type = 'button';
  return controlButton;
};

export const locationsListInnerHtml = (address, index) => {
  return `<div class="d-inline-block mr-1 mb-1">
    <div class="p-1 rounded" style="background: #f5f6f8;">
    <small class="px-1 font-weight-bold">${address}</small>
    <button data-removeIndex="${index}" type="button" class="btn btn-transparent btn-sm">
    <i class="fa fa-close"></i>
    </button>
    </div>
    </div>
    `;
};

export const getPolygonCoordinates = geometry => {
  let polygon = geometry.getPath().getArray();
  let coordinates = [];

  polygon.forEach(function (point) {
    // Invert to match Django 4326
    coordinates.push([point.lng(), point.lat()]);
  });

  // Check if the first and last points are the same
  if (coordinates.length > 1) {
    let firstPoint = coordinates[0];
    let lastPoint = coordinates[coordinates.length - 1];
    if (firstPoint[0] !== lastPoint[0] || firstPoint[1] !== lastPoint[1]) {
      // Add the first point again to close the polygon
      coordinates.push([firstPoint[0], firstPoint[1]]);
    }
  }

  return coordinates;
};

/*
Converts coordinates from [[lat, long], ..., [lat-n,long-n]]
To 'lat,long|...|lat-n,long-n'
*/
export const formatCoordinates = coordinates => {
  return coordinates.map(coord => coord.join(',')).join('|');
};

// Function to find the convex hull of a set of points
export const convexHull = points => {
  if (points.length <= 3) {
    return points; // No need to calculate the convex hull for 3 or fewer points
  }

  // Find the leftmost point
  var leftmost = 0;
  for (var i = 1; i < points.length; i++) {
    if (points[i][0] < points[leftmost][0]) {
      leftmost = i;
    }
  }

  var hull = [];
  var current = leftmost;
  do {
    hull.push(points[current]);
    current = nextPoint(points, current);
  } while (current !== leftmost);

  return hull;
};

// Function to calculate the cross product of three points
const crossProduct = (p1, p2, p3) => {
  return (p2[0] - p1[0]) * (p3[1] - p1[1]) - (p3[0] - p1[0]) * (p2[1] - p1[1]);
};

// Function to find the next point on the convex hull
const nextPoint = (points, current) => {
  var next = (current + 1) % points.length;
  for (var i = 0; i < points.length; i++) {
    if (crossProduct(points[current], points[next], points[i]) < 0) {
      next = i;
    }
  }
  return next;
};
