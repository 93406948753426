/* istanbul ignore file */

import { Controller } from '@hotwired/stimulus';

import {
  autoComplete,
  drawMap,
  drawCircle,
  drawPolygon,
  setMapBounds,
  setOnMap,
  drawingManager,
  hideDrawingManager,
  showDrawingManager,
  getCoreEvent,
  checkPolygonIsWithinBounds,
  addControlToMap,
} from '../utils/googleMapsUtils';
import {
  convexHull,
  createControlButton,
  formatCoordinates,
  getPolygonCoordinates,
  locationsListInnerHtml,
  toggleElementVisibility,
} from '../utils/zonesUtils';

const pageAction = {
  createPolygon: 'CREATE_POLYGON',
  createRadius: 'CREATE_RADIUS',
  editRadius: 'EDIT_RADIUS',
  editPolygon: 'EDIT_POLYGON',
  viewMap: 'VIEW_MAP',
};

export default class extends Controller {
  static targets = [
    'address',
    'placeId',
    'latitude',
    'longitude',
    'radius',
    'mapContainer',
    'boundary',
    'locationStrategy',
    'drawStrategy',
    'addPoint',
    'locationList',
  ];

  page = null;
  circle = null;
  polygon = null;

  defaultLat = 0.05034781599503812;
  defaultLong = 37.645456670838506;

  async connect() {
    this.page = this.element.dataset.page;

    switch (this.page) {
      case pageAction.createRadius:
        await processDrawRadius(this);
        break;
      case pageAction.createPolygon:
        await processDrawPolygon(this);
        break;
      case pageAction.viewMap:
        await processViewMap(this);
        break;
      case pageAction.editRadius:
        await processEditRadius(this);
        break;
      case pageAction.editPolygon:
        await processEditPolygon(this);
        break;
      default:
        console.error('Invalid Page Action!');
        break;
    }
  }
}

/*
EDIT POLYGON
*/

const processEditPolygon = async controller => {
  const { map, polygon } = await setEditMap(controller);
  const drawingTool = await drawingManager();
  setOnMap(drawingTool, map);
  hideDrawingManager(drawingTool);
  const currentPolygon = await drawCurrentPolygon(controller, map, true);
  if (polygon) {
    await setMapBounds(map, polygon);
  }
  listenToPolygonChanges(controller, currentPolygon);
  createResetButton(controller, currentPolygon, drawingTool, map);
  await listenToDrawing(controller, drawingTool, map, polygon);
};

const createResetButton = async (controller, geometry, drawingTool, map) => {
  const centerControlDiv = document.createElement('div');
  const resetButton = createControlButton(
    'Reset Shape',
    'Click to reset the shape',
  );

  resetButton.addEventListener('click', () => {
    controller.boundaryTarget.value = null;
    geometry.setMap(null);
    showDrawingManager(drawingTool);
    centerControlDiv.remove();
  });

  centerControlDiv.appendChild(resetButton);
  addControlToMap(map, centerControlDiv);
};

/*
EDIT CIRCLE
*/

const processEditRadius = async controller => {
  const { map } = await setEditMap(controller);
  const autocomplete = await setupAutocomplete(controller);
  setCircleInputsListener(controller, map, autocomplete);
};

const setEditMap = async controller => {
  const center = getCentralPosition(controller);
  const map = await drawMap(controller.mapContainerTarget, center);
  await drawCurrentPolygon(controller, map, false, '#939495');
  const parentPolygon = await drawParentPolygon(controller, map);
  await drawPolygonChildren(controller, map);
  return { map: map, polygon: parentPolygon };
};

/*
VIEW MAP
*/

const processViewMap = async controller => {
  const center = getCentralPosition(controller);
  const map = await drawMap(controller.mapContainerTarget, center);
  await drawCurrentPolygon(controller, map, false, '#7da733');
  await drawPolygonChildren(controller, map);
};

const drawCurrentPolygon = async (controller, map, editable = false, color) => {
  const parentBoundary = JSON.parse(controller.element.dataset.currentCoords);
  const parentPolygon = await drawPolygon(parentBoundary, editable, color);
  setOnMap(parentPolygon, map);
  await setMapBounds(map, parentPolygon);
  return parentPolygon;
};

/*
DRAW POLYGON
*/

const processDrawPolygon = async controller => {
  const { map, polygon } = await setCreateMap(controller);
  const drawingTool = await drawingManager();
  setOnMap(drawingTool, map);
  hideDrawingManager(drawingTool);
  const autocomplete = await setupAutocomplete(controller);
  setPolygonStrategyListeners(controller, drawingTool);
  setAddPointListener(controller, autocomplete, map);
  listenToDrawing(controller, drawingTool, map, polygon);
};

const setPolygonStrategyListeners = async (controller, drawingTool) => {
  controller.drawStrategyTarget.addEventListener('change', async () => {
    controller.boundaryTarget.value = '';
    showDrawingManager(drawingTool);
    toggleElementVisibility(controller.addPointTarget, 'OFF');

    if (controller.polygon != null) {
      controller.polygon.setMap(null);
    }
  });

  controller.locationStrategyTarget.addEventListener('change', async () => {
    controller.boundaryTarget.value = '';
    hideDrawingManager(drawingTool);
    toggleElementVisibility(controller.addPointTarget, 'ON');
    controller.locationListTarget.innerHTML = '';
  });
};

const setAddPointListener = async (controller, autocomplete, map) => {
  let points = [];

  autocomplete.addListener('place_changed', async () => {
    const place = autocomplete.getPlace();
    let lat = place.geometry.location.lat();
    let long = place.geometry.location.lng();
    let address = controller.addressTarget.value;
    points.push([lat, long, address]);
    populateLocationList(controller, points, map);
    await drawPolygonFromConvexHull(controller, points, map);
    controller.addressTarget.value = '';
  });
};

export const populateLocationList = (controller, points, map) => {
  let locationTarget = controller.locationListTarget;
  locationTarget.innerHTML = '';
  points.forEach((point, index) => {
    const locationTagHTML = locationsListInnerHtml(point[2], index);
    locationTarget.insertAdjacentHTML('beforeend', locationTagHTML);
    let button = document.querySelector(`button[data-removeIndex="${index}"]`);
    button.addEventListener('click', async () => {
      points.splice(index, 1);
      populateLocationList(controller, points, map);
      await drawPolygonFromConvexHull(controller, points, map);
    });
  });
};

const drawPolygonFromConvexHull = async (controller, points, map) => {
  if (controller.polygon != null) {
    controller.polygon.setMap(null);
  }

  if (points.length >= 3) {
    let boundary = convexHull(points);
    controller.polygon = await drawPolygon(boundary, true);
    setOnMap(controller.polygon, map);
    listenToPolygonChanges(controller, controller.polygon);
  }
};

const listenToPolygonChanges = async (controller, geometry) => {
  const event = await getCoreEvent();

  event.addListener(geometry, 'dragend', () => {
    updatePolygonOnChange(controller, geometry);
  });
  event.addListener(geometry.getPath(), 'insert_at', () => {
    updatePolygonOnChange(controller, geometry);
  });
  event.addListener(geometry.getPath(), 'set_at', () => {
    updatePolygonOnChange(controller, geometry);
  });
};

const updatePolygonOnChange = (controller, geometry) => {
  let coordinates = getPolygonCoordinates(geometry);
  controller.boundaryTarget.value = formatCoordinates(coordinates);
};

const listenToDrawing = async (controller, drawingTool, map, boundary) => {
  const event = await getCoreEvent();

  event.addListener(drawingTool, 'polygoncomplete', async geometry => {
    if (boundary) {
      let validPolygon = await checkPolygonIsWithinBounds(geometry, boundary);

      if (validPolygon) {
        let geometryCoordinates = getPolygonCoordinates(geometry);
        controller.boundaryTarget.value =
          formatCoordinates(geometryCoordinates);
        hideDrawingManager(drawingTool);
        listenToPolygonChanges(controller, geometry);
        createClearButton(controller, geometry, drawingTool, map);
      } else {
        alert('Drawing must be within the allowed area.');
        geometry.setMap(null);
      }
    }
  });
};

const createClearButton = async (controller, geometry, drawingTool, map) => {
  const centerControlDiv = document.createElement('div');
  const clearButton = createControlButton(
    'Clear Shape',
    'Click to remove the shape',
  );

  clearButton.addEventListener('click', () => {
    controller.boundaryTarget.value = null;
    geometry.setMap(null);
    showDrawingManager(drawingTool);
    centerControlDiv.remove();
  });

  centerControlDiv.appendChild(clearButton);
  addControlToMap(map, centerControlDiv);
};

/*
DRAW CIRCLE
*/

const processDrawRadius = async controller => {
  const { map } = await setCreateMap(controller);
  const autocomplete = await setupAutocomplete(controller);
  setCircleInputsListener(controller, map, autocomplete);
};

const setCreateMap = async controller => {
  const center = getCentralPosition(controller);
  const map = await drawMap(controller.mapContainerTarget, center);
  const parentPolygon = await drawParentPolygon(controller, map);
  await drawPolygonChildren(controller, map);
  return { map: map, polygon: parentPolygon };
};

const getCentralPosition = controller => {
  return { lat: controller.defaultLat, lng: controller.defaultLong };
};

const drawParentPolygon = async (controller, map) => {
  const parentBoundary = JSON.parse(controller.element.dataset.parentCoords);
  if (parentBoundary.length > 2) {
    const parentPolygon = await drawPolygon(parentBoundary, false, '#7da733');
    setOnMap(parentPolygon, map);
    await setMapBounds(map, parentPolygon);
    return parentPolygon;
  }
};

const drawPolygonChildren = async (controller, map) => {
  const polygonChildren = JSON.parse(controller.element.dataset.childrenCoords);
  polygonChildren.forEach(async element => {
    const childPolygon = await drawPolygon(element, false, '#015162');
    setOnMap(childPolygon, map);
  });
};

const setupAutocomplete = async controller => {
  return await autoComplete(controller.addressTarget);
};

const setCircleInputsListener = (controller, map, autocomplete) => {
  let coordinatesReady = false,
    radiusReady = false;

  if (controller.page == pageAction.editRadius) {
    coordinatesReady = true;
  }

  autocomplete.addListener('place_changed', async () => {
    const place = autocomplete.getPlace();
    controller.latitudeTarget.value = place.geometry.location.lat();
    controller.longitudeTarget.value = place.geometry.location.lng();
    controller.placeIdTarget.value = place.place_id;
    coordinatesReady = true;
    if (coordinatesReady && radiusReady) {
      await drawCirclePolygon(controller, map);
    }
  });

  controller.radiusTarget.addEventListener('input', async () => {
    radiusReady = true;
    if (coordinatesReady && radiusReady) {
      await drawCirclePolygon(controller, map);
    }
  });
};

const drawCirclePolygon = async (controller, map) => {
  let lat = controller.latitudeTarget.value;
  let long = controller.longitudeTarget.value;
  let radius = controller.radiusTarget.value * 1000;

  if (controller.circle != null) {
    controller.circle.setMap(null);
  }

  controller.circle = await drawCircle(lat, long, radius, '#FF0000');
  setOnMap(controller.circle, map);
};
